.p-banner {
    $root: &;

    &_dragItem {
        box-shadow: 0 1px 4px rgba(33, 33, 52, 0.1);
        border-radius: rem(8);
        background-color: $white;
        position: relative;
        transition: $transition;
        overflow: hidden;
        border: 1px solid $white;

        &-active {
            border-color: $yellow-green;

            #{$root}_dragHeader {
                border-bottom: 1px solid $gainsboro;
                background-color: $beige;

                &_arrow {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &_dragHeader {
        position: relative;
        padding: rem(16) rem(16) rem(16) rem(16);
        display: flex;
        align-items: center;

        &_holder,
        &_arrow {
            display: flex;
            align-items: center;
            margin-right: rem(8);
        }

        &_arrow {
            transition: $transition;
        }
    }

    &_dragContent {
        padding: rem(16);
    }
}
