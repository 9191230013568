.e-usageHistory {
    &-card {
        .ant-card-head {
            padding: 0 12px;
        }

        .ant-card-body {
            padding: 12px;
        }

        &-item {
            &:not(:last-child) {
                padding-bottom: 12px;
                border-bottom: 1px dashed #e8e8e8;
            }

            &:not(:first-child) {
                margin-top: 24px;
            }
        }
    }
}
