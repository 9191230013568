/* stylelint-disable selector-no-qualifying-type */
.tfc {
    &-table {
        th.ant-table-cell {
            white-space: nowrap;
            text-transform: uppercase;
            padding-left: 12px !important;
            padding-right: 12px !important;
        }

        td.ant-table-cell {
            padding-left: 12px !important;
            padding-right: 12px !important;
        }
    }
}
