.e-apis {
    padding: rem(40) rem(20);
    margin-top: rem(60);

    &-content {
        padding: 0 rem(120);
        margin-top: rem(48);

        &-item {
            width: 100%;
            padding: rem(48) rem(16);
            background-color: #498ddd;
            @include adjust-flex;
            border-radius: rem(6);

            &.failed {
                background-color: #e34964;
            }

            &.latency {
                background-color: #69b45a;
            }

            &-text {
                color: $white;
                @include font-base(16, 24);
                text-align: center;
            }
        }
    }

    &-contentOverview {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: rem(32);
    }

    .anticon {
        margin-left: 0;
    }
}
