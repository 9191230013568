.p-solutionSearch {
    $root: &;

    &_wrapper {
        position: relative;

        &:hover {
            #{$root}_result {
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }

            #{$root}_icon {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &_result {
        span {
            display: block;
            @include text-overflow(4);
            padding: rem(4);
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $black025;
            border-radius: rem(10);
            opacity: 0;
            visibility: hidden;
            transition: all $transition ease-in-out;
        }
    }

    &_icon {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all $transition ease-in-out;
    }
}
