.p-editSolutionSearch {
    &_customInput {
        background-color: #0000000a;
        border-radius: 4px;
        padding: 11px;
        border: 1px solid #d9d9d9;
        min-height: rem(20);
        
        span {
            display: block;
            min-height: rem(20);
        }
    }
}
