// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$davys-grey: rgb(75, 84, 92); // #4b545c
$cool-black: rgb(0, 43, 96); // #002b60
$lavender-blue: rgb(194, 222, 255); // #c2deff
$yellow-green: rgb(141, 198, 63); //#8DC63F
$spanish-gray: rgb(159, 159, 159); //#9F9F9F
$red: rgb(255, 0, 0); //#ff0000
$eerie-black: rgb(31, 31, 31); //#1F1F1F
$quartz: rgb(74, 74, 74); //#4a4a4a
$red-orange: rgb(255, 77, 79); //#ff4d4f
$brilliant-azure: rgb(64, 169, 255); //#40a9ff
$kelly-green: rgb(82, 196, 26); //#52c41a
$gainsboro: rgb(220, 220, 228); //#dcdce4
$sunset-orange: rgb(238, 94, 82); //#ee5e52
$emerald: rgb(92, 177, 118); //#5cb176
$la-salle-green: rgb(0, 102, 33); //#006621
$medium-blue: rgb(30, 15, 190); //#1e0fbe
$gray: rgb(128, 128, 128); //#808080
$davys-grey2: rgb(84, 84, 84); //#545454
$bronze: rgb(217, 130, 47); //#d9822f
$almond: rgb(242, 224, 206); //#f2e0ce
$amazon: rgb(50, 128, 72); //#328048
$glitter: rgb(234, 234, 239); //#eaeaef
$anti-flash-white: rgb(240, 240, 240); //#f0f0f0
$anti-flash-whitef1: rgb(241, 241, 241); //#f1f1f1
$gray-x11: rgb(191, 191, 191); // #bfbfbf
$dim-gray: rgb(101, 101, 101); // #656565;
$azureish-white: rgb(229, 234, 239); // #e5eaef
$taupe-gray: rgb(140, 140, 140); //#8c8c8c
$gainsboro: rgb(217, 217, 217); //#D9D9D9
$white-smoke: rgb(245, 245, 245); //#f5f5f5
$deep-moss-green: rgb(47, 104, 70); //#2f6846
$honeydew: rgb(234, 251, 231); //#eafbe7
$sea-blue: rgb(0, 96, 150); //#006096
$alice-blue: rgb(234, 245, 255); //#eaf5ff
$tea-green: rgb(211, 227, 192); //#d3e3c0
$platinum: rgb(227, 227, 227); //#e3e3e3
$sapphire: rgb(14, 78, 173); //#0e4ead
$green: rgb(107, 164, 58); //#6ba43a
$dodger-blue: rgb(24, 144, 255); //#1890ff
$beige: rgb(233, 241, 223); //#e9f1df
$air-force-blue: rgb(102, 128, 160); //#6680a0
$light-cobalt-blue: rgb(142, 151, 243); //#8e97f3
$baby-blue-eyes: rgb(169, 176, 253); //#a9b0fd
$pearl-aqua: rgb(112, 200, 188); //#70c8bc
$pale-robin-egg-blue: rgb(145, 212, 203); //#91d4cb
$light-coral: rgb(241, 137, 138); //#f1898a
$mauvelous: rgb(244, 164, 164); //#f4a4a4
$medium-aquamarine: rgb(108, 219, 186); //#6cdbba
$lavender-floral: rgb(165, 118, 240); //#a576f0
$blueberry: rgb(77, 137, 255); //#4d89ff
$lavender: rgb(220, 224, 255); //#dce0ff
$queen-blue: rgb(58, 108, 145); //#3a6c91
$iris: rgb(91, 57, 196); //#5b39c4
$persian-blue: rgb(38, 52, 180); //#2634b4
$may-green: rgb(95, 158, 69); //#5f9e45
$raisin-black: rgb(38, 38, 38); //#262626

// Overlays
$black025: rgba($black, 0.25);
$black022: rgba($black, 0.22);
$cool-black02: rgba($cool-black, 0.2);
$cool-black05: rgba($cool-black, 0.5);
$cool-black07: rgba($cool-black, 0.7);
$sapphire02: rgba($sapphire, 0.2);
$green02: rgba($green, 0.2);

// Linears
