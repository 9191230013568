.role-table-cell {
    td {
        padding: rem(8) !important;

        &:first-child {
            padding-left: rem(24) !important;
        }
    }
}

.p-roleDetail {
    &_form {
        height: 100%;
        padding-top: 90px;
        display: flex;
        flex-direction: column;
    }

    &_input {
        flex-shrink: 0;
    }

    &_table {
        flex-grow: 1;
        position: relative;

        &_content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            
            * {
                @include scrollbars(8, $gainsboro, transparent);
            }
        }
    }
}
